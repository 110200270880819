import { Box, List, Text, VStack } from '@chakra-ui/react';
import * as _ from 'lodash';
import { useState } from 'react';

import UserAvatar from 'src/components/UserAvatar';
import { TranslationKeys } from 'src/constants/translation-keys';
import { useUserDetails } from 'src/hoc/UserDetailsProvider';
import { useTranslate } from 'src/hooks/useTranslate';
import { CallSession } from 'src/models/CallSession.model';

import CallItem from '../components/CallItem';
import usePersonalCalls from '../hooks/usePersonalCalls';

const PersonalCallsPanel = () => {
  const { translate } = useTranslate();
  const { personalCalls } = usePersonalCalls();
  const [visibleItem, setVisibleItem] = useState<number | null>(null);
  const { currentUser } = useUserDetails();
  const time24Hour = (date: Date) => {
    return Intl.DateTimeFormat('en-US', {
      day: 'numeric',
      month: 'short',
      hour12: true,
      hour: '2-digit',
      minute: '2-digit',
    }).format(new Date(date));
  };

  const handleVisibility = (id: number) => {
    setVisibleItem(prev => (prev === id ? null : id));
  };

  const selectCallerOrReceiverId = (session: CallSession) => {
    return session.CallerId === currentUser?.id
      ? session.ReceiverId
      : session.CallerId;
  };

  return (
    <Box position='relative'>
      <List spacing='4' paddingInline='0'>
        {_.isEmpty(personalCalls) && (
          <VStack mt='12'>
            <Text fontSize='lg' color='gray.500' fontWeight='semibold'>
              {translate(TranslationKeys.youHaveNoCallsYet)}
            </Text>
            <Text fontSize='sm' color='gray.500'>
              {translate(TranslationKeys.createACall)}
            </Text>
          </VStack>
        )}
        {/* render all latest items */}
        {_.chain(personalCalls)
          .orderBy(({ createdAt }) => createdAt, 'desc')
          .uniqBy(({ session }) => selectCallerOrReceiverId(session))
          .value()
          .map(({ id, otherUser, session, createdAt, callStatus }) => (
            <CallItem
              onClick={() => handleVisibility(id)}
              key={id}
              callerId={session.CallerId}
              receiverId={session.ReceiverId}
              joinedAt={session.joinedAt}
              callStatus={callStatus}
              title={otherUser.fullName}
              subtitle={time24Hour(createdAt)}
              avatarComponent={<UserAvatar name={otherUser.fullName} />}
              isVisible={visibleItem === id}
              personalCalls={personalCalls}
            />
          ))}
      </List>
    </Box>
  );
};

export default PersonalCallsPanel;
