import { HStack, ListItem, Text, VStack } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';

import { LacsColors } from 'src/constants/common.constants';
import { useUserDetails } from 'src/hoc/UserDetailsProvider';
import { PersonalCalls } from 'src/models/PersonalCall';
import { ColorShaderMap } from 'src/utils';
import { listVariants } from 'src/constants/calls.constant';
import { Call } from 'src/models/Call.model';

import { CallHistory } from './CallHistory';
import CallIcons from './CallIcons';
import { getOtherUserId } from './getOtherUserId';
import PersonalVoiceCallButton from './PersonalVoiceCallButton';
import { getColorForCallIcon } from '../utils/call.util';

export interface CallItemProps {
  callerId: number;
  receiverId: number;
  joinedAt: Date | null;
  avatarComponent?: React.ReactNode;
  title: React.ReactNode;
  subtitle: React.ReactNode;
  isVisible: boolean;
  onClick?: () => void;
  personalCalls?: PersonalCalls[];
  callStatus: Call['callStatus'];
}

const CallItem: React.FC<CallItemProps> = ({
  callerId,
  receiverId,
  joinedAt,
  avatarComponent,
  title,
  subtitle,
  isVisible,
  onClick: handleVisibility,
  personalCalls,
  callStatus,
}) => {
  // get current user
  const { currentUser } = useUserDetails();
  // get Other User Id
  const [otherUserId, setOtherUserId] = useState<number>();

  useEffect(() => {
    if (currentUser) {
      setOtherUserId(
        getOtherUserId(
          { CallerId: callerId, ReceiverId: receiverId },
          currentUser.id,
        ),
      );
    }
  }, [currentUser, callerId, receiverId]);

  const blueOrTransparentBorder = isVisible
    ? ColorShaderMap.blue[500]
    : LacsColors.transparent;

  const grayTransparentOrBlueBorder = !isVisible
    ? ColorShaderMap.gray[200]
    : ColorShaderMap.blue[500];

  const arePersonalCallsMoreThanOne = personalCalls && personalCalls.length > 1;

  return (
    <ListItem w='full' as={motion.div} variants={listVariants} cursor='pointer'>
      <ListItem
        onClick={arePersonalCallsMoreThanOne ? handleVisibility : undefined}
        border={isVisible ? '5px solid' : '1px solid'}
        borderColor={blueOrTransparentBorder}
        borderBottomColor={grayTransparentOrBlueBorder}
        as={HStack}
        py='2'
        px='2'
      >
        {avatarComponent}
        <VStack spacing='1' align='start' w='full' minW='0'>
          <HStack justifyContent='space-between' w='full'>
            <Text fontWeight='bold'>{title}</Text>
          </HStack>
          <HStack w='full'>
            {/* 
             - fetch current user
             - compare if current user is caller it means that the call is outgoing
             - if the current user is receiver , it means that the call is incoming
            

             - make individual components fot them

             - check if call is joined..simply check value of joinedAt
             - if the call is joined so no matter weather the call is incoming or outgoing it will always green
             - if call is not joined by anyone so  icons will be red
             */}

            {currentUser && (
              <CallIcons
                callerId={callerId}
                currentUser={currentUser}
                callStatus={callStatus}
                color={getColorForCallIcon(joinedAt)}
              />
            )}

            <Text
              pr='75'
              overflow='hidden'
              whiteSpace='nowrap'
              textOverflow='ellipsis'
              maxW='80%'
              fontWeight='normal'
              color='gray.500'
            >
              {subtitle}
            </Text>
          </HStack>
        </VStack>

        {/* to make a call while button click
          1- get other user id using function getOtherUserId(callerId, currentUserId)
          2- then pass the other user id in the component as a props
          3- pass this id to useNewCall function as an argument and also return the icon.
        */}

        <PersonalVoiceCallButton otherUserId={otherUserId ?? 0} />

        {arePersonalCallsMoreThanOne &&
          (isVisible ? <IoIosArrowUp /> : <IoIosArrowDown />)}
      </ListItem>
      {arePersonalCallsMoreThanOne && isVisible && (
        <CallHistory
          callerId={callerId}
          receiverId={receiverId}
          personalCalls={personalCalls}
        />
      )}
    </ListItem>
  );
};

export default CallItem;
