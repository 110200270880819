import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
} from '@chakra-ui/react';
import { UseFormRegister } from 'react-hook-form';

import { UpdateUserType } from 'src/types/user.type';

interface CommonInputBoxProps {
  label: string;
  value: keyof UpdateUserType;
  register: UseFormRegister<UpdateUserType>;
  error: string | undefined;
  inputType?: string;
}

export const CommonInputBox: React.FC<CommonInputBoxProps> = ({
  label,
  value,
  register,
  error,
  inputType,
}) => {
  return (
    <FormControl isInvalid={!!error}>
      <FormLabel>{label}</FormLabel>
      <Input
        disabled={value === 'email'}
        type={inputType}
        textColor='black'
        {...register(value)}
      />
      <FormErrorMessage>{error}</FormErrorMessage>
    </FormControl>
  );
};
