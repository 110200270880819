import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Flex,
  HStack,
  List,
  ListItem,
  Text,
  VStack,
} from '@chakra-ui/react';
import * as _ from 'lodash';

import { TranslationKeys } from 'src/constants/translation-keys';
import { useTranslate } from 'src/hooks/useTranslate';
import { getCallerName } from 'src/utils/get-call-details';

import { GroupCallIcon } from '../../components/GroupCallIcon';
import RandomGroupCallButton from '../../components/RandomGroupCallButton';
import GroupVoiceCallButton from '../../components/GroupVoiceCallButton';
import useGroupCalls from '../../hooks/useGroupCalls';
import { CallAvatar } from '../../components/CallAvatar';
import { GroupCallTable } from '../../components/GroupCallTable';

const GroupCallsPanel = () => {
  const { translate } = useTranslate();
  const { groupCalls } = useGroupCalls();
  const time24HourWithDay = (date: Date) => {
    return Intl.DateTimeFormat('en-US', {
      day: 'numeric',
      month: 'short',
      hour12: true,
      hour: '2-digit',
      minute: '2-digit',
    }).format(new Date(date));
  };

  return (
    <List spacing='4' paddingInline='0'>
      {_.isEmpty(groupCalls) && (
        <VStack mt='12'>
          <Text fontSize='lg' color='gray.500' fontWeight='semibold'>
            {translate(TranslationKeys.youDontHaveGroupCalls)}
          </Text>
        </VStack>
      )}
      <Accordion allowMultiple>
        {_.chain(groupCalls)
          .orderBy(({ createdAt }) => createdAt, 'desc')
          .value()
          .map(
            ({
              messageGroup,
              session,
              MessageGroupId,
              createdAt,
              callStatus,
            }) => {
              return (
                <AccordionItem key={MessageGroupId}>
                  <ListItem py='2' as={AccordionButton}>
                    <Flex pr='5'>
                      <CallAvatar
                        sessions={session}
                        messageGroup={messageGroup}
                      />
                    </Flex>
                    <Flex flexDirection='column' w='full' flex='1'>
                      <HStack w='full'>
                        <Flex>
                          <Flex>
                            <Text
                              textAlign='start'
                              fontWeight='bold'
                              fontSize='lg'
                              noOfLines={1}
                            >
                              {getCallerName(messageGroup, session)}
                            </Text>
                          </Flex>
                        </Flex>
                      </HStack>
                      <HStack w='full'>
                        <Flex>
                          <Flex mr='2'>
                            <Text fontWeight='bold'>
                              <GroupCallIcon
                                sessions={session}
                                callStatus={callStatus}
                              />
                            </Text>
                          </Flex>
                          <Flex>
                            <Text
                              noOfLines={1}
                              fontWeight='normal'
                              color='gray.500'
                              whiteSpace='nowrap'
                            >
                              {time24HourWithDay(createdAt)}
                            </Text>
                          </Flex>
                        </Flex>
                      </HStack>
                    </Flex>
                    <Flex
                      flex='1'
                      justifyContent='flex-end'
                      pr='2'
                      alignItems='center'
                    >
                      <Flex>
                        {MessageGroupId ? (
                          <GroupVoiceCallButton
                            groupMessageThreadId={messageGroup.MessageThreadId}
                            groupId={MessageGroupId}
                          />
                        ) : (
                          <RandomGroupCallButton sessions={session} />
                        )}
                      </Flex>
                    </Flex>
                    <Flex>
                      <HStack w='full' as={Flex} justifyContent='flex-end'>
                        <AccordionIcon />
                      </HStack>
                    </Flex>
                  </ListItem>
                  <AccordionPanel px={0} pb={4}>
                    <GroupCallTable sessions={session} />
                  </AccordionPanel>
                </AccordionItem>
              );
            },
          )}
      </Accordion>
    </List>
  );
};
export default GroupCallsPanel;
