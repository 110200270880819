export enum CallType {
  Personal = 'personal',
  Group = 'group',
}

export enum CallStatusType {
  connected = 'Connected',
  missed = 'Missed',
  rejected = 'Rejected',
  unreachable = 'Unreachable',
  notConnected = 'Not Connected',
}

export enum VideoQualityLevels {
  low = 'low',
  medium = 'medium',
  high = 'high',
}

export enum ReportType {
  outboundRtp = 'outbound-rtp',
  inboundRtp = 'inbound-rtp',
}
