import { IconButton, IconButtonProps } from '@chakra-ui/react';
import { Link } from 'react-router-dom';

interface NavbarLeftIconProps extends IconButtonProps {
  to?: string;
}

export const NavbarLeftIcon: React.FC<NavbarLeftIconProps> = props => {
  return (
    <IconButton
      variant='ghost'
      borderRight='1px solid'
      borderColor='gray.200'
      _focus={{}}
      _hover={{}}
      size='lg'
      as={Link}
      {...props}
    />
  );
};
